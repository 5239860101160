<template>
  <div class="row">
    <div class="col-md-12" id="div_reload">
      <div class="card">
        <div class="card-body">
          <div class="col-md-4 float-sm-right" style="padding-right: 0px !important;">
            <input type="text" v-model="searchQuery" class="form-control form-control mb-3" placeholder="Type here for search..." style="border: 2px solid #00000054 !important;">
          </div>
          <form action="">
            <table class="table table-hover table-bordered text-center">
              <thead>
              <tr>
                <th :class="{change_color: scrollPosition > 150}">Name</th>
                <th :class="{change_color: scrollPosition > 150}">Unit</th>
                <th :class="{change_color: scrollPosition > 150}">Last Requisition Date</th>
                <th :class="{change_color: scrollPosition > 150}">Last Requisition Request</th>
                <th :class="{change_color: scrollPosition > 150}">Last Requisition Approved</th>
                <th :class="{change_color: scrollPosition > 150}">Consumption From Last Requisition</th>
                <th :class="{change_color: scrollPosition > 150}">Current Stock</th>
                <th :class="{change_color: scrollPosition > 150}">Update Qty</th>
                <th :class="{change_color: scrollPosition > 150}">Comment</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(product, index) in resultQuery" :key="index">
                <td>{{ product.name }}</td>
                <td>{{ product.unit }}</td>
                <td>{{ product.last_requisition_item ? customDate(product.last_requisition_item.last_requisition_item_created_at) : 'N/A' }}</td>
                <td>{{ product.last_requisition_item ? product.last_requisition_item.last_requisition_request_amount : 0 }}</td>
                <td>{{ product.last_requisition_item ? product.last_requisition_item.last_requisition_accepted_amount : 'N/A' }}</td>
                <td>{{ product.last_requisition_item_consumption ? product.last_requisition_item_consumption[product.id] : 'N/A' }}</td>
                <td>{{ product.hub_product_stock ? product.hub_product_stock.stock_amount : 0 }}</td>
                <td>
                  <input type="number" class="form-control form-control-sm"  :id="'amount_'+product.id" v-model="product.amount" v-validate="{ max: 6, regex: /^[0-9]*$/ }" :name="'amount' + index">
                </td>
                <td>
                  <input type="text" class="form-control form-control-sm"  :id="'req_comment_'+product.id" v-model="product.req_comment" v-validate="{ max: 255 }" :name="'req_comment' + index">
                </td>
              </tr>
              </tbody>
            </table>
            <a-skeleton active :loading="loading"></a-skeleton>
            <a-button class="btn btn-success waves-effect waves-light mr-2" :loading="updateRequisition" @click.prevent="update"><i class="fa fa-edit mr-1" aria-hidden="true"></i>Update Products Requisition</a-button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import apiClient from '@/services/axios'
import $ from 'jquery'

export default {
  name: 'update',
  components: { },
  data() {
    return {
      products: [],
      product_ids: [],
      requisition_items: [],
      validation_errors: {},
      loader: false,
      loading: false,
      updateRequisition: false,
      searchQuery: '',
      scrollPosition: null,
    }
  },
  computed: {
    resultQuery () {
      if (this.searchQuery) {
        return this.products.filter(product =>
          product.name.toLowerCase().includes(this.searchQuery.toLowerCase()),
        )
      } else {
        return this.products
      }
    },
  },
  mounted() {
    this.onUpdate()
    window.addEventListener('scroll', this.updateScroll)
  },
  methods: {
    customDate(date) {
      return moment(date).format('LL') || 'N/A'
    },
    onUpdate() {
      const requisitionId = this.$route.params.requisition_id
      this.loading = true
      apiClient.get('api/hub/requisitions/edit/' + requisitionId)
        .then(response => {
          this.loading = false
          if (!response.data.error) {
            const data = response.data
            this.products = data.products
            this.product_ids = data.product_ids
          } else {
            this.$notification.error({
              message: response.data.message,
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    update() {
      this.$validator.validate().then(valid => {
        if (valid) {
          const requisitionArray = []
          this.product_ids.forEach(id => {
            const amountDiv = '#amount_' + id
            const amount = $(amountDiv).val()

            const reqCommentDiv = '#req_comment_' + id
            const reqComment = $(reqCommentDiv).val()
            if (amount) {
              const requisition = {
                product_id: id,
                amount: amount,
                req_comment: reqComment,
              }
              requisitionArray.push(requisition)
            }
          })

          if (requisitionArray.length === 0) {
            this.$notification.error({
              message: 'Insert at least 1 item',
            })
            return
          }
          this.updateRequisition = true
          const formData = new FormData()
          formData.append('requisition_items', JSON.stringify(requisitionArray))
          const requisitionId = this.$route.params.requisition_id
          apiClient.post('api/hub/requisitions/update/' + requisitionId, formData, { headers: { 'Content-Type': 'multipart/form-data', 'Content-type': 'application/json' } })
            .then(response => {
              if (!response.data.error) {
                this.$notification.success({
                  message: response.data.message,
                })
                this.updateRequisition = false
                this.$router.push({ name: 'hubRequisitionHistory' })
              } else {
                this.updateRequisition = false
                this.$notification.error({
                  message: response.data.message,
                })
              }
            }).catch(error => {
              this.updateRequisition = false
              this.show = true
              this.validation_errors = error.response.data.errors
              this.hide()
            })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
    updateScroll() {
      this.scrollPosition = window.scrollY
    },
  },
}
</script>

<style scoped>
  table {
    position: relative;
  }
  th {
    position: sticky;
    top: 0;
    background: #ffffff;
    z-index: 1;
  }
  .change_color {
    background-color: #4B7CF3;
    color: #ffffff;
  }
</style>
